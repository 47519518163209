<template>
  <div>
    <!-- Task Card Header -->
    <b-card class="card mb-1" title="Edit Data Kelas">
      <!-- Title -->
      <div class="d-flex pb-2 border-bottom">
        <b-avatar
          :src="blobImage"
          :text="avatarText('Kelas')"
          :variant="`light-primary`"
          size="104px"
          rounded
        />
        <div class="ml-1 d-flex flex-column justify-content-center">
          <div class="d-flex mb-1">
            <input
              accept="image/png, image/jpg, image/jpeg"
              ref="file"
              type="file"
              style="display: none"
              @change="onFileChange"
            />
            <b-button
              variant="outline-primary small-gap-right"
              @click="$refs.file.click()"
            >
              Upload Foto Baru
            </b-button>
            <b-button @click="blobImage = ''" variant="secondary">
              Reset
            </b-button>
          </div>
          <span>Upload foto kelas format jpg/png maks. 800kb</span>
        </div>
      </div>

      <!-- Classroom Config -->
      <div class="mt-2">
        <b-row>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Nama Kelas</label>
            <b-form-input
              id="classroom-name"
              v-model="formData.classroomName"
              placeholder="Masukkan Nama Kelas"
              :state="formErrors.classroomName.state"
            />
            <b-form-invalid-feedback v-show="!formErrors.classroomName.state">
              {{ formErrors.classroomName.message }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col lg="6" class="mb-1">
            <label class="d-block">Icon Kelas</label>
            <b-avatar
              v-for="icon in iconList"
              :key="icon"
              class="small-gap-right pointer"
              :variant="
                formData.selectedIcon === icon
                  ? 'light-success'
                  : 'light-secondary'
              "
              rounded=""
            >
              <i
                class="card-icon ti"
                :class="icon"
                @click="formData.selectedIcon = icon"
              />
            </b-avatar>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Deskripsi Kelas</label>
            <b-form-textarea
              v-model="formData.classroomDescription"
              placeholder="Deskripsi Kelas"
              rows="3"
              no-resize
              :state="formErrors.classroomDescription.state"
            />
            <b-form-invalid-feedback
              v-show="!formErrors.classroomDescription.state"
            >
              {{ formErrors.classroomDescription.message }}
            </b-form-invalid-feedback>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Tipe Kelas</label>
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="isOpenClass"
                name="check-button"
                switch
                inline
                class="mr-0"
                @change="isOpenClassChange"
              />
              <span class="-mr-1 line-height-small">
                Terbuka
              </span>
            </div>
          </b-col>
          <b-col v-show="!isOpenClass" lg="12" class="mb-1">
            <label class="d-block">Kata Sandi Kelas</label>
            <b-form-input
              id="classroom-name"
              v-model="formData.classroomEnrollKey"
              placeholder="Masukan Kata Sandi Kelas"
            />
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Jadwal Kelas</label>
            <b-form-group :state="formErrors.classroomSchedule.state">
              <v-select
                v-model="formData.classroomSchedule"
                label="title"
                :options="schedules"
              />
            </b-form-group>
            <div
              v-show="!formErrors.classroomSchedule.state"
              class="invalid-feedback"
            >
              {{ formErrors.classroomSchedule.message }}
            </div>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Tahun Ajaran</label>
            <b-form-group :state="formErrors.classroomYear.state">
              <v-select
                v-model="formData.classroomYear"
                label="title"
                placeholder="Pilih Tahun Ajaran"
                :options="classroomYearsList"
              />
            </b-form-group>
            <div
              v-show="!formErrors.classroomYear.state"
              class="invalid-feedback"
            >
              {{ formErrors.classroomYear.message }}
            </div>
          </b-col>
          <b-col lg="12" class="mb-1">
            <label class="d-block">Guru Kelas</label>
            <b-form-group :state="formErrors.classroomTeacher.state">
              <v-select
                v-model="formData.classroomTeacher"
                label="title"
                placeholder="Pilih Guru"
                :options="classroomTeacherList"
              />
            </b-form-group>
            <div
              v-show="!formErrors.classroomTeacher.state"
              class="invalid-feedback"
            >
              {{ formErrors.classroomTeacher.message }}
            </div>
          </b-col>
          <b-col lg="12">
            <label class="d-block">Kelas Mulai</label>
            <b-form-group :state="!formErrors.classroomStartDate.state">
              <flat-pickr
                v-model="formData.classroomStartDate"
                class="form-control"
                :class="{
                  'is-invalid': formErrors.classroomStartDate.state,
                }"
                :config="{
                  altInput: true,
                  altFormat: 'j F Y',
                  dateFormat: 'Y-m-d',
                }"
              />
            </b-form-group>
            <div
              v-show="!formErrors.classroomStartDate.state"
              class="invalid-feedback"
            >
              {{ formErrors.classroomStartDate.message }}
            </div>
          </b-col>
        </b-row>
        <div>
          <b-button variant="primary" class="float-right" @click="submitForm">
            <div class="d-flex align-items-center ">
              <b-spinner
                v-show="isLoading"
                class="mr-1"
                variant="light"
                small
              />
              Simpan
            </div>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
// * Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// * Utils
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'
import { avatarText, pascalCase } from '@core/utils/filter'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BSpinner,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      // State
      isLoading: false,
      isOpenClass: false,
      formErrors: {
        classroomName: { state: null, message: '' },
        classroomDescription: { state: null, message: '' },
        classroomSchedule: { state: null, message: '' },
        classroomTeacher: { state: null, message: '' },
        classroomYear: { state: null, message: '' },
        classroomStartDate: { state: null, message: '' },
      },

      // Data
      classroomId: this.$router.currentRoute.params.id,

      // Form Data
      blobImage: '',
      iconList: [
        'ti-book',
        'ti-calculator',
        'ti-map-search',
        'ti-flag',
        'ti-history',
        'ti-affiliate',
      ],
      schedules: [
        'Senin',
        'Selasa',
        'Rabu',
        'Kamis',
        'Jumat',
        'Sabtu',
        'Minggu',
      ],
      classroomTeacherList: [],
      classroomYearsList: [],

      formData: {
        classroomName: '',
        selectedIcon: 'ti-book',
        classroomEnrollKey: '',
        classroomImage: '',
        classroomCover: '',
        classroomSchedule: 'Senin',
        classroomTeacher: null,
        classroomYear: null,
        classroomDescription: '',
        classroomStartDate: '',
        schoolId: '',
      },
      temporaryEnrollKey: '',
    }
  },
  async mounted() {
    this.formData.classroomStartDate = new Date().toISOString().slice(0, 10)
    this.formData.schoolId = JSON.parse(
      localStorage.getItem('userData')
    ).school_id

    // * Get Classroom Teacher List
    client
      .get('/school-admin/users?role=Teacher&per_page=9999999')
      .then((res) => {
        this.classroomTeacherList = res.data.data.data.map((item) => {
          return {
            title: this.pascalCase(item.name),
            value: item.id,
          }
        })
      })

    client.get('/utils/school-years').then((res) => {
      this.classroomYearsList = res.data.data.map((item) => {
        return {
          title: `${this.pascalCase(item.period)} - ${this.pascalCase(
            item.name
          )}`,
          value: item.id,
        }
      })
    })

    // * Get Classroom Data
    await client
      .get(`/school-admin/classrooms/${this.classroomId}`)
      .then((res) => {
        this.formData.classroomName = res.data.data.name
        this.formData.selectedIcon = res.data.data.logo
        this.formData.classroomEnrollKey = res.data.data.enroll_key
        this.formData.classroomImage = res.data.data.image
        this.blobImage = res.data.data.image
        this.formData.classroomCover = res.data.data.image
        this.formData.classroomSchedule = res.data.data.schedule
        this.formData.classroomTeacher = {
          title: this.pascalCase(res.data.data.teacher.name),
          value: res.data.data.teacher.id,
        }
        this.formData.classroomYear = this.classroomYearsList.find(
          (year) => year.value === res.data.data.school_year_id
        )

        this.formData.classroomDescription = res.data.data.description
        this.formData.classroomStartDate = res.data.data.start_date
        this.formData.classroomEnrollKey = res.data.data.enrollment_key
        this.temporaryEnrollKey = res.data.data.enrollment_key

        if (res.data.data.enrollment_key === null) {
          this.isOpenClass = true
        }
      })
  },
  methods: {
    avatarText,
    pascalCase,
    generateRandomString(length) {
      let result = ''
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        )
      }
      return result
    },
    isOpenClassChange() {
      if (this.isOpenClass) {
        this.formData.classroomEnrollKey = null
      } else {
        if (this.temporaryEnrollKey === 'null') {
          this.formData.classroomEnrollKey = this.generateRandomString(6)
        } else {
          this.formData.classroomEnrollKey = this.temporaryEnrollKey
        }
      }
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.formData.classroomLogo = file
      this.blobImage = URL.createObjectURL(file)
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      let isValid = true
      if (this.formData.classroomName === '') {
        this.formErrors.classroomName = {
          state: false,
          message: 'Nama Kelas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.classroomName = {
          state: null,
          message: '',
        }
      }
      if (this.formData.classroomDescription === '') {
        this.formErrors.classroomDescription = {
          state: false,
          message: 'Deskripsi Kelas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.classroomDescription = {
          state: null,
          message: '',
        }
      }
      if (this.formData.classroomSchedule === '') {
        this.formErrors.classroomSchedule = {
          state: false,
          message: 'Jadwal Kelas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.classroomSchedule = {
          state: null,
          message: '',
        }
      }
      if (this.formData.classroomStartDate === '') {
        this.formErrors.classroomStartDate = {
          state: false,
          message: 'Tanggal Mulai Kelas tidak boleh kosong',
        }
        isValid = false
      } else {
        this.formErrors.classroomStartDate = {
          state: null,
          message: '',
        }
      }
      return isValid
    },
    async submitForm() {
      this.isLoading = true

      if (this.validateForm()) {
        const data = new FormData()
        data.append('name', this.formData.classroomName)
        data.append('description', this.formData.classroomDescription)
        data.append('logo', this.formData.selectedIcon)
        data.append('image', this.formData.classroomLogo)
        data.append('cover', this.formData.classroomLogo)
        data.append('schedule', this.formData.classroomSchedule)
        data.append('user_id', this.formData.classroomTeacher.value)
        if (!this.isOpenClass) {
          data.append('enrollment_key', this.formData.classroomEnrollKey)
        }
        data.append('school_id', this.formData.schoolId)
        data.append('start_date', this.formData.classroomStartDate)
        data.append('schoolYear', this.formData.classroomYear.value)
        data.append('_method', 'PUT')

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }

        // update classroom
        await client
          .post(`/classrooms/${this.classroomId}`, data, config)
          .then((response) => {
            // stop loading
            this.isLoading = false

            // show success toast
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Kelas berhasil diedit'
            )

            // redirect to classroom detail
            this.$router.push({
              name: checkAuthorizeRole('classrooms'),
            })
          })
          .catch((error) => {
            this.isLoading = false
            console.error(error)
            // show success toast
            this.showToast(
              'error',
              'XIcon',
              'Gagal',
              error.response.data.message
            )
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.line-height-small {
  line-height: 1.5rem;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.vs__clear {
  display: none;
}
</style>
